import { identifyUser } from '@/utilities/hubspot/identify'

const content = document.querySelector('[data-cy="content"]')

// Identify the logged in user for the HubSpot conversations widget
window.addEventListener('cypress:user:loaded', identifyUser)

// Add margin to the top of the content when the banner is visible
window.addEventListener('banner:visible', () => {
  content.classList.add('mt-banner')
})

// Remove margin from the top of the content when the banner is dismissed
window.addEventListener('banner:dismissed', () => {
  content.classList.remove('mt-banner')
})
