import { isLoggedIn, fetchPayingUser, currentUser } from '@/stores'

let userLoaded = false
let hsConversationsLoaded = false
let hsTokenFetched = false

// Initialize the HubSpot conversation widget settings
window.hsConversationsSettings = {
  loadImmediately: false,
}

// Initialize the HubSpot conversation widget
window.hsConversationsOnReady = [
  () => {
    hsConversationsLoaded = true
    fetchToken()
  },
]

// Request the identification token from the back-end API
export async function getIdentificationToken(currentUser: any) {
  try {
    const response = await fetch('/api/hubspot/identify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: currentUser?.value?.email,
        firstName: currentUser?.value?.firstName,
        lastName: currentUser?.value?.lastName,
      }),
    })

    if (!response.ok) {
      throw new Error(`${response.status}`)
    }

    const data = await response.json()
    return data.token
  } catch (error) {
    console.error('Failed to get HubSpot identification token:', error)
    return null
  }
}

// Identify the logged in user for the HubSpot conversations widget
export async function identifyUser() {
  userLoaded = true
  fetchToken()
}

// Fetch the identification token from the back-end API
async function fetchToken() {
  if (userLoaded && hsConversationsLoaded) {
    if (!hsTokenFetched && isLoggedIn.value === true && currentUser?.value?.email) {
      hsTokenFetched = true
      const token = await getIdentificationToken(currentUser)
      window.hsConversationsSettings.identificationEmail = currentUser.value.email
      window.hsConversationsSettings.identificationToken = token
      window.HubSpotConversations.widget.load()
    } else if (isLoggedIn.value === false) {
      window.HubSpotConversations.widget.load()
    }
  }
}
